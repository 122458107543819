import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </StyledEngineProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
