import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Typography,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

interface TermsDialogProps {
  open: boolean;
  onClose: () => void;
}

const TermsDialog: FC<TermsDialogProps> = (props) => {
  const { onClose, open } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogTitle>
        Termos de Uso
      </DialogTitle>
      <DialogContent
        dividers
      >
        <Typography
          color="textSecondary"
          sx={{ mt: 1 }}
          variant="body2"
        >
          Ao utilizar o Freds, pelo site freds.app ou número de telefone explícito +55 11 98880-1955, você
          automaticamente concorda com todas as regras deste termo e demais condições aqui mencionadas,
          responsabilizando-se integralmente por todos e quaisquer atos praticados por você durante o uso do Freds.
          Caso você não concorde com qualquer dos termos e condições abaixo estabelecidos, você não deve
          utilizar o Freds.
          <br />
          Este Termo estará disponível para leitura a qualquer momento, por meio do endereço freds.app. Com o
          intuito de garantir o constante aperfeiçoamento deste documento e do Freds o presente Termo poderá ser
          eventualmente alterados por nós.
          <br />
          Dessa forma, recomendamos que o presente Termo seja verificado periodicamente pelo Usuário, de modo a
          garantir sua ciência acerca de toda e qualquer alteração eventualmente realizada neste documento, tendo como
          base a data de atualização indicada abaixo.
        </Typography>
        <br />
        <Typography
          color="textPrimary"
          variant="h6"
        >
          1. Segurança da Informação
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ mt: 1 }}
          variant="body2"
        >
          Seus dados são protegidas e criptografados para sua privacidade e segurança. Não existirá como acessar a sua
          conta por meio de um site ou serviço de terceiros, sendo assim, deverá acessar apenas pelas canais
          informados neste termo.
          <br />
          Com a finalidade de garantir a sua privacidade e a proteção dos seus dados pessoais, utilizamos
          criptografia tanto em nossos bancos de dados como nas comunicações de dados que realizamos.
          <br />
          Nos esforçamos para proteger a privacidade de sua conta e de outros dados pessoais que mantemos em
          nossos registros, mas infelizmente não podemos garantir total segurança. Acesso ou uso não autorizado de
          conta, falha de hardware ou
          software e outros fatores podem comprometer a segurança dos seus dados pessoais a qualquer momento, por
          isso, nos ajude a manter um ambiente seguro para todos. Além de adotar boas práticas de segurança em relação
          a sua conta e aos seus dados, caso você identifique ou tome conhecimento de algo que comprometa a segurança
          dos seus dados, por favor entre em contato conosco.
        </Typography>
        <br />
        <Typography
          color="textPrimary"
          variant="h6"
        >
          2. Contato
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ mt: 1 }}
          variant="body2"
        >
          Se você acredita que suas informações pessoais foram usadas de maneira incompatível com esta Política de
          Privacidade ou com as suas escolhas enquanto titular destes dados, ou, ainda, se você tiver outras dúvidas,
          comentários ou sugestões relacionadas a esta Política, você pode entrar em contato com nosso time nos
          seguintes endereços de contato:
          <br />
          Telefone: +55 11 98880-1955
          <br />
          E-mail: guilherme@freds.app
        </Typography>
        <br />
        <Typography
          color="textPrimary"
          variant="h6"
        >
          3. Alterações na Política de Privacidade
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ mt: 1 }}
          variant="body2"
        >
          Como estamos sempre buscando melhorar nossas Plataformas, essa Política de Privacidade pode passar por
          atualizações. Dessa forma, recomendamos visitar periodicamente esta página para que você tenha conhecimento
          sobre as modificações. Caso sejam feitas alterações relevantes que ensejam um novo consentimento seu, iremos
          publicar essa atualização e solicitar um novo consentimento para você.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

TermsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default TermsDialog;
