import React from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { createCustomTheme } from './theme';
import routes from './routes';

const App: React.FC = () => {
  const content = useRoutes(routes);
  const theme = createCustomTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {content}
    </ThemeProvider>
  );
};

export default App;
