import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import Footer from './Footer';
import MainNavbar from './MainNavbar';
import PrivacyDialog from './PrivacyDialog';
import TermsDialog from './TermsDialog';

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayoutRoot = experimentalStyled('div')(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.background.default,
      height: '100%',
      paddingTop: 80
    }
  )
);

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const [openPrivacyDialog, setOpenPrivacyDialog] = useState(false);
  const handleOpenPrivacyDialog = () => {
    setOpenPrivacyDialog(true);
  };
  const handleClosePrivacyDialog = () => {
    setOpenPrivacyDialog(false);
  };
  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const handleOpenTermsDialog = () => {
    setOpenTermsDialog(true);
  };
  const handleCloseTermsDialog = () => {
    setOpenTermsDialog(false);
  };

  return (
    <MainLayoutRoot>
      <MainNavbar />
      {children || <Outlet />}
      <Footer
        openPrivacyDialog={handleOpenPrivacyDialog}
        openTermsDialog={handleOpenTermsDialog}
      />
      <PrivacyDialog
        open={openPrivacyDialog}
        onClose={handleClosePrivacyDialog}
      />
      <TermsDialog
        open={openTermsDialog}
        onClose={handleCloseTermsDialog}
      />
    </MainLayoutRoot>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
