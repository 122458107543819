import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Button, Divider, Toolbar } from '@material-ui/core';
import Logo from './Logo';

const MainNavbar: FC = () => (
  <AppBar
    elevation={0}
    sx={{
      backgroundColor: 'background.paper',
      color: 'text.secondary'
    }}
  >
    <Toolbar style={{ minHeight: 80 }}>
      <RouterLink to="/">
        <Logo
          sx={{
            display: {
              md: 'inline'
            },
            height: 50,
            paddingTop: 1,
            paddingLeft: 5,
            paddingRight: 5
          }}
        />
      </RouterLink>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          alignItems: 'center',
          display: {
            md: 'flex',
            xs: 'none'
          }
        }}
      >
        <Button
          color="primary"
          component="a"
          variant="contained"
          href="https://cliente.freds.app/"
          size="large"
          target="_blank"
        >
          Ir para plataforma
        </Button>
      </Box>
    </Toolbar>
    <Divider />
  </AppBar>
);

export default MainNavbar;
