import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Typography,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

interface PrivacyDialogProps {
  open: boolean;
  onClose: () => void;
}

const PrivacyDialog: FC<PrivacyDialogProps> = (props) => {
  const { onClose, open } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogTitle>
        Política de Privacidade
      </DialogTitle>
      <DialogContent
        dividers
      >
        <Typography
          color="textSecondary"
          sx={{ mt: 1 }}
          variant="body2"
        >
          A aceitação desta Política será feita quando você utilizar nossas Plataformas para usufruir de nossos
          Serviços. Tal aceitação se dará mediante o momento em que você, de forma afirmativa, concordar com o uso dos
          seus dados para cada uma das finalidades por nós aqui descritas. Isso indicará que você está ciente e em
          total acordo com a forma como utilizaremos as suas informações e os seus dados. Caso não concorde com esta
          Política, por favor, não continue o seu procedimento de registro e não use nossas Plataformas. Todavia, por
          favor, nos informes os motivos de sua eventual discordância para que possamos melhorar nossos Termos de Uso
          e Política de Privacidade. Este documento foi redigido com linguagem simples, utilizando-se vários exemplos
          de coleta e de uso dos dados, justamente para que você possa compreender a forma como utilizamos os seus
          dados.
        </Typography>
        <br />
        <Typography
          color="textPrimary"
          variant="h6"
        >
          1. Dados Coletados
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ mt: 1 }}
          variant="body2"
        >
          A quantidade e o tipo de informações coletadas pelas nossas Plataformas variam conforme o uso que você faz.
          Coletaremos diferentes dados caso você esteja visitando nosso website ou realizando compras em nosso
          aplicativo. E para tornar mais acessível essas definições e diferenças, elaboramos a tabela abaixo para que
          você possa compreender o que se aplica especificamente à você.
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ mt: 1 }}
          variant="body2"
        >
          Dados Pessoais de Nome e E-mail serão pedidos no formulário do site, após isso ao clicar no botão para
          realizar a ação teremos acesso ao número de telefone, caso o usuário envie a mensagem. Após isso, apenas
          teremos acesso ao que o próprio usuário passar.
        </Typography>
        <br />
        <Typography
          color="textPrimary"
          variant="h6"
        >
          2. Tempo de armazenamento
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ mt: 1 }}
          variant="body2"
        >
          Manteremos seus dados pessoais somente pelo tempo que for necessário para cumprir com as finalidades para as
          quais os coletamos, inclusive para fins de cumprimento de quaisquer obrigações legais, contratuais, de
          prestação de contas ou requisição de autoridades competentes. Para determinar o período de retenção adequado
          para os dados pessoais, consideramos a quantidade, a natureza e a sensibilidade dos dados pessoais, o risco
          potencial de danos decorrentes do uso não autorizado ou da divulgação de seus dados pessoais, a finalidade
          de processamento dos seus dados pessoais e se podemos alcançar tais propósitos através de outros meios, e os
          requisitos legais aplicáveis.
        </Typography>
        <br />
        <Typography
          color="textPrimary"
          variant="h6"
        >
          3. Segurança da Informação
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ mt: 1 }}
          variant="body2"
        >
          Com a finalidade de garantir a sua privacidade e a proteção dos seus dados pessoais, utilizamos criptografia
          tanto em nossos bancos de dados como nas comunicações de dados que realizamos.
          Nos esforçamos para proteger a privacidade de sua conta e de outros dados pessoais que mantemos em nossos
          registros, mas infelizmente não podemos garantir total segurança. Acesso ou uso não autorizado de conta,
          falha de hardware ou software e outros fatores podem comprometer a segurança dos seus dados pessoais a
          qualquer momento, por isso, nos ajude a manter um ambiente seguro para todos. Além de adotar boas práticas
          de segurança em relação a sua conta e aos seus dados, caso você identifique ou tome conhecimento de algo que
          comprometa a segurança dos seus dados, por favor entre em contato conosco.
        </Typography>
        <br />
        <Typography
          color="textPrimary"
          variant="h6"
        >
          4. Contato
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ mt: 1 }}
          variant="body2"
        >
          Se você acredita que suas informações pessoais foram usadas de maneira incompatível com esta Política de
          Privacidade ou com as suas escolhas enquanto titular destes dados, ou, ainda, se você tiver outras dúvidas,
          comentários ou sugestões relacionadas a esta Política, você pode entrar em contato com nosso time nos
          seguintes endereços de contato:
          <br />
          Telefone: +55 11 98880-1955
          <br />
          E-mail: guilherme@freds.app
        </Typography>
        <br />
        <Typography
          color="textPrimary"
          variant="h6"
        >
          5. Alterações na Política de Privacidade
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ mt: 1 }}
          variant="body2"
        >
          Como estamos sempre buscando melhorar nossas Plataformas, essa Política de Privacidade pode passar por
          atualizações. Dessa forma, recomendamos visitar periodicamente esta página para que você tenha conhecimento
          sobre as modificações. Caso sejam feitas alterações relevantes que ensejam um novo consentimento seu, iremos
          publicar essa atualização e solicitar um novo consentimento para você.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

PrivacyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default PrivacyDialog;
