import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { Theme } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { SxProps } from '@material-ui/system';

interface LogoProps {
  dark?: boolean;
  sx?: SxProps<Theme>;
}

const LogoRoot = experimentalStyled('img')``;

const Logo: FC<LogoProps> = (props) => (
  <LogoRoot
    src="static/logo-freds-dark.png"
    {...props}
  />
);

Logo.propTypes = {
  dark: PropTypes.bool
};

export default Logo;
