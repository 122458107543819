import merge from 'lodash/merge';
import { createTheme } from '@material-ui/core/styles';
import type { Theme, ThemeOptions } from '@material-ui/core';
import { lightShadows } from './shadows';
import 'typeface-open-sans';

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box'
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%'
        },
        '#root': {
          height: '100%'
        },
        '#nprogress .bar': {
          zIndex: '2000 !important'
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        }
      }
    }
  },
  typography: {
    button: {
      fontWeight: 600
    },
    fontFamily: 'Open Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 650,
      fontSize: '3.5rem',
      color: '#f87c56'
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem',
      color: '#f87c56'
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem'
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem'
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem'
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: '1.250rem'
    },
    overline: {
      fontWeight: 600
    }
  }
};

const themesOptions: ThemeOptions = {
  palette: {
    action: {
      active: '#6b778c'
    },
    background: {
      default: '#f4f5f7',
      paper: '#ffffff'
    },
    error: {
      contrastText: '#ffffff',
      main: '#f44336'
    },
    primary: {
      contrastText: '#ffffff',
      main: '#05c3dd'
    },
    secondary: {
      contrastText: '#ffffff',
      main: '#6244bb'
    },
    success: {
      contrastText: '#ffffff',
      main: '#4caf50'
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    },
    warning: {
      contrastText: '#ffffff',
      main: '#ff9800'
    }
  },
  shadows: lightShadows
};

export const createCustomTheme = (): Theme => {
  const themeOptions = themesOptions;
  const theme = createTheme(
    merge(
      {},
      baseOptions,
      themeOptions
    )
  );

  return theme;
};
