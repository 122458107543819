import { FC } from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import MinusIcon from '../icons/Minus';
import Logo from './Logo';

interface FooterProps {
  openPrivacyDialog: () => void;
  openTermsDialog: () => void;
}

const Footer: FC<FooterProps> = (props) => {
  const { openPrivacyDialog, openTermsDialog } = props;
  const sections = [
    {
      title: 'Menu',
      links: [
        {
          title: 'Plataforma',
          href: 'http://cliente.freds.app/'
        }
      ]
    },
    {
      title: 'Segurança',
      links: [
        {
          title: 'Privacidade',
          href: '#',
          onclick: openPrivacyDialog
        },
        {
          title: 'Termos de Uso',
          href: '#',
          onclick: openTermsDialog
        }
      ]
    },
    {
      title: 'Contato',
      links: [
        {
          title: 'Email: contato@freds.app',
          href: 'mailto:contato@freds.app'
        },
        {
          title: 'Telefone: +55 11 988801955',
          href: 'https://wa.me/5511988801955'
        },
        {
          title: 'Freds Soluções e Serviços de Informática LTDA.',
          href: '#'
        }
      ]
    }
  ];

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        pb: 6,
        pt: {
          md: 15,
          xs: 6
        }
      }}
      {...props}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={3}
            sm={4}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              order: {
                md: 1,
                xs: 4
              },
              padding: '55px',
              maxHeight: '150px'
            }}
          >
            <Logo
              sx={{
                width: {
                  sm: '100%',
                  xs: '50%'
                }
              }}
            />
            <Typography
              color="textSecondary"
              sx={{ mt: 1 }}
              variant="caption"
            >
              © 2021 Freds.app
            </Typography>
          </Grid>
          {sections.map((section, index) => (
            <Grid
              item
              key={section.title}
              md={3}
              sm={4}
              sx={{
                order: {
                  md: index + 2,
                  xs: index + 1
                }
              }}
              xs={12}
            >
              <Typography
                color="textSecondary"
                variant="overline"
              >
                {section.title}
              </Typography>
              <List disablePadding>
                {section.links.map((link) => (
                  <ListItem
                    disableGutters
                    key={link.title}
                    sx={{
                      pb: 0,
                      pt: 1
                    }}
                  >
                    {link.title !== '' && (
                      <ListItemAvatar
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          minWidth: 0,
                          mr: 0.5
                        }}
                      >
                        <MinusIcon color="secondary" />
                      </ListItemAvatar>
                    )}
                    <ListItemText
                      primary={(
                        <Link
                          href={link.href}
                          target={link.href !== '#' ? '_blank' : ''}
                          onClick={link.onclick}
                          color="textPrimary"
                          variant="subtitle2"
                        >
                          {link.title}
                        </Link>
                      )}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))}
        </Grid>
        <Divider
          sx={{
            borderColor: (theme) => alpha(theme.palette.primary.contrastText, 0.12),
            my: 6
          }}
        />
        <Typography
          color="textSecondary"
          variant="caption"
        >
          Todos os direitos reservados
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
